/*=========================================================================================
  File Name: moduleAuthGetters.js
  Description: Auth Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Legal Board Services
  Author: Enkompass
  Author URL: https://www.enkompass.net
==========================================================================================*/


export default {}
