import { Auth } from 'aws-amplify'
import axios from '../http/axios/index.js'
import store from '../store/store.js'

export default {
  init () {

    // Set the AUTH token for any request
    axios.interceptors.request.use(function (config) {

      //Get new access token
      Auth.currentSession().then(credentials => {
        localStorage.setItem('accessToken', credentials.idToken.jwtToken)
        localStorage.setItem('loggedIn', true)
      }).catch(err => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loggedIn");
      })

      const token = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : ''
      try {
        if (config && config.url && !config.url.startsWith("http")) {
          config.headers.Authorization =  token ? `Bearer ${token}` : ''
        }
      } catch(e){}
      return config
    })

    // Add a 401 response interceptor
    let oldXHROpen = window.XMLHttpRequest.prototype.open;window.XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
     this.addEventListener('load', function() {
      if(this.status == 401){
        if(this.responseText === '{"message":"The incoming token has expired"}'){
          //Get new access token
          Auth.currentSession().then(credentials => {
            localStorage.setItem('accessToken', credentials.idToken.jwtToken)
            localStorage.setItem('loggedIn', true)
            location.reload();
          }).catch(err => {
            console.log(err);
            location.href = "/login";
          })
        }
      }
     });
     return oldXHROpen.apply(this, arguments);
    }

    // Note : Axios Interceptor for response was not firing for 401 response.
    // Never entered in the error or response block, so used js method above
    // axios.interceptors.response.use(function (response) {
    //     return response;
    // }, function (error) {
    //     if (401 === error.response.status) {
    //         // handle error: inform user, go to login, etc
    //     } else {
    //         return Promise.reject(error);
    //     }
    // });
  },

  login (payload) {
    //If the payload has not been set, or if it is a new login, try to login user
    if (!payload.challenge) {
      return new Promise((resolve, reject) => {
        Auth.signIn(payload.userDetails.email, payload.userDetails.password)
          .then(response => {
            if (response && response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
              //Push all the value inside payload
              payload.challenge = response.challengeName
              payload.loginResponse = response
              payload.message = 'New password must be set, before you can continue.'
              reject(payload)
            } else {
              resolve(this.getCurrentSession())
            }
          }).catch(error => {
            if(error.code === 'PasswordResetRequiredException'){
              this.forgotPassword(payload.userDetails.email)
                .then(err => {
                  err.code = error.code
                  err.message = "Please change your password to continue."
                  reject(err)
                })
            } else {
              reject(error)
            }
          })
      })
    } else {
        return new Promise((resolve, reject) => {
          switch(payload.challenge) {
            case 'NEW_PASSWORD_REQUIRED':
              Auth.completeNewPassword(payload.sessionData, payload.userDetails.password)
                .then(response => {
                  //Log User In automatically
                  // resolve(this.getCurrentSession())
                  response.type = 'SUCC_NEW_PWD_CREATE'
                  resolve(response)
                }).catch(error => {
                    reject(error)
                })
              break
          }
        })
    }
  },
  register (name, email, password) {
    return new Promise((resolve, reject) => {
      axios.post('register_user', {
        name,
        email,
        password
      })
      .then(resp => {
        resolve(resp.data);
      })
      .catch(err => {
        reject(err.response.data.message)
      })
    })
  },
  forgotPassword (email) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then(data => {
          resolve(data.CodeDeliveryDetails)
        })
        .catch(err => {
          reject(err)
        });
    })
  },
  verifyChallenge (email, code, new_password) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email, code, new_password)
      .then(resp => {
        resolve(resp)
      }).catch(error => {
        reject(error)
      });
    })
  },
  enterChallenge (email, code) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email)
        .then(data => {
          resolve(data.CodeDeliveryDetails)
        })
        .catch(err => {
          reject(err)
        });
    })
  },
  refreshToken () {
    return axios.post('/api/auth/refresh-token', {accessToken: localStorage.getItem('accessToKen')})
  },
  //Get Current Session Information, Fetches Tokens
  getCurrentSession () {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
      .then(credentials => {
        if (credentials && credentials.idToken && credentials.idToken.jwtToken) {

          //Set access token
          localStorage.setItem('accessToken', credentials.idToken.jwtToken)
          localStorage.setItem('loggedIn', true)

          //Get Authenticated User Info
          Auth.currentAuthenticatedUser()
          .then(cognitoUser => {

            if (cognitoUser) {
              axios.get('me')
              .then(function (userProfile) {
                resolve({credentials, cognitoUser, userProfile})
              })
            } else reject({message: 'ERR_NO_USR_DTLS : Error getting user details.'})
          }).catch(reject)
        } else reject({message: 'ERR_NO_SESS_CRED : Error getting session credentials.'})
      }).catch(reject)
    })
  }
}
