// axios
import axios from 'axios'

export default axios.create({
  // baseURL: window.location.hostname == 'localhost' ? 'http://localhost:3000/dev/' : process.env.VUE_APP_ROOT_API,
  baseURL: process.env.VUE_APP_ROOT_API,
  headers: {
    'Content-Type': 'application/json'
  },
  params: {}
})
