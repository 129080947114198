/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Legal Board Services
  Author: Enkompass
  Author URL: https://www.enkompass.net
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import auth from '@/auth/authService'

import 'firebase/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [

    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
      path: '',
      component: () => import('./layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          name: "home",
          // redirect: '/dashboard/ecommerce'
          component: () => import('./views/Dashboard.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases',
          name: 'account-cases',
          component: () => import('./views/apps/todo/AccountCaseList.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents',
          name: 'account-case-documents',
          component: () => import('./views/apps/todo/AccountCaseDocumentList.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents/readyforslicing',
          name: 'case-document-readyforslicing',
          component: () => import('./views/apps/todo/CaseDocumentReadyForSlicing.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents/:documentId/pdfslicer',
          name: 'case-document-pdfslicer',
          component: () => import('./views/apps/todo/CaseDocumentSlicer.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents/:documentId/keydata',
          name: 'case-document-keydata',
          component: () => import('./views/apps/todo/CaseDocumentKeyDataReport.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents/fullbatesreport',
          name: 'case-document-full-bates',
          component: () => import('./views/apps/todo/CaseDocumentFullBatesReport.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/cases/:caseId/documents/:documentId/screenshots',
          name: 'case-document-screenshots',
          component: () => import('./views/apps/todo/CaseDocumentScreenshots.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
          }
        },
        {
          path: '/accounts/:accountId/users',
          name: 'account-users',
          component: () => import('./views/apps/todo/AccountUserList.vue'),
          meta: {
            rule: 'admin',
            authRequired: true,
            firmCheckPoint: true,
            trelloCheckPoint: true
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: '',
      component: () => import('@/layouts/full-page/FullPage.vue'),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: '/callback',
          name: 'auth-callback',
          component: () => import('@/views/Callback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/checkpoint/firm',
          name: 'checkpoint-firm',
          component: () => import('@/views/pages/checkpoint/Firm.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/checkpoint/trello',
          name: 'checkpoint-trello',
          component: () => import('@/views/pages/checkpoint/Trello.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/checkpoint/trello/callback',
          name: 'checkpoint-trello-callback',
          component: () => import('@/views/pages/checkpoint/TrelloCallback.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/login',
          name: 'page-login',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/login',
          name: 'login-page',
          component: () => import('@/views/pages/login/Login.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/register',
          name: 'page-register',
          component: () => import('@/views/pages/register/Register.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/auth/forgot-password',
          name: 'page-forgot-password',
          component: () => import('@/views/pages/ForgotPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/auth/challenge',
          name: 'page-password-challenge',
          component: () => import('@/views/pages/PasswordChallenge.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/auth/update-password',
          name: 'page-update-password',
          component: () => import('@/views/pages/UpdatePassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/reset-password',
          name: 'page-reset-password',
          component: () => import('@/views/pages/ResetPassword.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/lock-screen',
          name: 'page-lock-screen',
          component: () => import('@/views/pages/LockScreen.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/comingsoon',
          name: 'page-coming-soon',
          component: () => import('@/views/pages/ComingSoon.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-404',
          name: 'page-error-404',
          component: () => import('@/views/pages/Error404.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/error-500',
          name: 'page-error-500',
          component: () => import('@/views/pages/Error500.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/not-authorized',
          name: 'page-not-authorized',
          component: () => import('@/views/pages/NotAuthorized.vue'),
          meta: {
            rule: 'editor'
          }
        },
        {
          path: '/pages/maintenance',
          name: 'page-maintenance',
          component: () => import('@/views/pages/Maintenance.vue'),
          meta: {
            rule: 'editor'
          }
        }
      ]
    },
    // Redirect to 404 page, if no match found
    {
      path: '*',
      redirect: '/pages/error-404'
    }
  ]
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  // If auth required, check login. If login fails redirect to login page
  if (to.meta.authRequired) {
    if (!(auth.isAuthenticated())) {
      router.push({ path: '/login', query: { to: to.path } }).catch(err => {})
    }
  }

  return next()
})

export default router
